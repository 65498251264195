<template>
  <v-app>
    <div class="d-flex mb-4">
      <div class="d-flex align-self-start">
        <h1>Fluentify &lt;&gt; Mailchimp Batch List</h1>
      </div>
      <div><es-btn @click="fetchBatchList" color="indigo"><fa icon="sync" /> Refresh</es-btn></div>
    </div>
      <v-card class="mb-6">
        <v-data-table 
          hide-default-footer
          fixed-header
          :loading="isBusy"
          :headers="headers"
          :items="batchItems"
          :items-per-page='1000'
          height="70vh"
          loading-text="Loading items ..."
          no-data-text="No items found.">
          <div v-show="isBusy" slot="progress" class="dot-windmill"></div>
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to=" item.status !== 'finished' ? '' : {name: 'fluentify-details', params: { batchId: item.id }}">{{ item.id }}</router-link>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="capitalize">{{  item.status }}</span>
          </template>
          <template v-slot:[`item.operation_report`]="{ item }">
            <v-chip  class="ma-2" small label :color=" item.status === 'finished' ? 'success' : 'info'" text-color="white" variant="elevated">{{ `${item.status === 'finished' ? 'Success' : 'In-Queue'} ${item.total_operations - item.errored_operations}` }}</v-chip>
            <v-chip class="ma-2" small label color="red" text-color="white" variant="elevated">{{ `Error ${item.errored_operations}` }}</v-chip>
            <!-- <v-chip class="ma-2" small label>Completion rate {{ Number((item.finished_operations/item.total_operations) * 100).toFixed(0) }} %</v-chip> -->
            <v-chip class="ma-2" small label>Total Items {{ item.total_operations }}</v-chip>
          </template>
          <template v-slot:[`item.submitted_at`]="{ item }">
            {{  item.submitted_at | dateString }}
          </template>
          <template v-slot:[`item.completed_at`]="{ item }">
            {{  item.completed_at | dateString }}
          </template>          <template v-slot:[`item.actions`]="{ item }">

          <div class="actions d-flex">
            <es-btn
              v-if="item.status === 'finished'"
              title="View details"
              class="es-btn es-btn-copy ml-auto"
              color="primary"
              @click="() => $router.push({ name: 'fluentify-details', params: { batchId: item.id } })"
            >View Details</es-btn>
          </div>
          </template>
        </v-data-table>
      </v-card>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'FluentifyList',
  computed: {
    ...mapGetters('fluentify', ['batchItems', 'headers', 'isBusy'])
  },
  methods: {
    ...mapActions('fluentify', ['fetchBatchList'])
  },
  mounted() {
    this.fetchBatchList();
  }
};
</script>
<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
</style>
