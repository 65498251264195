var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"d-flex mb-4"},[_c('div',{staticClass:"d-flex align-self-start"},[_c('h1',[_vm._v("Fluentify <> Mailchimp Batch List")])]),_c('div',[_c('es-btn',{attrs:{"color":"indigo"},on:{"click":_vm.fetchBatchList}},[_c('fa',{attrs:{"icon":"sync"}}),_vm._v(" Refresh")],1)],1)]),_c('v-card',{staticClass:"mb-6"},[_c('v-data-table',{attrs:{"hide-default-footer":"","fixed-header":"","loading":_vm.isBusy,"headers":_vm.headers,"items":_vm.batchItems,"items-per-page":1000,"height":"70vh","loading-text":"Loading items ...","no-data-text":"No items found."},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":item.status !== 'finished' ? '' : {name: 'fluentify-details', params: { batchId: item.id }}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]}},{key:"item.operation_report",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":item.status === 'finished' ? 'success' : 'info',"text-color":"white","variant":"elevated"}},[_vm._v(_vm._s(((item.status === 'finished' ? 'Success' : 'In-Queue') + " " + (item.total_operations - item.errored_operations))))]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":"","color":"red","text-color":"white","variant":"elevated"}},[_vm._v(_vm._s(("Error " + (item.errored_operations))))]),_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","label":""}},[_vm._v("Total Items "+_vm._s(item.total_operations))])]}},{key:"item.submitted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateString")(item.submitted_at))+" ")]}},{key:"item.completed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateString")(item.completed_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions d-flex"},[(item.status === 'finished')?_c('es-btn',{staticClass:"es-btn es-btn-copy ml-auto",attrs:{"title":"View details","color":"primary"},on:{"click":function () { return _vm.$router.push({ name: 'fluentify-details', params: { batchId: item.id } }); }}},[_vm._v("View Details")]):_vm._e()],1)]}}],null,true)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBusy),expression:"isBusy"}],staticClass:"dot-windmill",attrs:{"slot":"progress"},slot:"progress"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }